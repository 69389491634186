import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, TrailguidePlugin } from "../../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Málaga is the perfect bike destination for the wintertime. From October to
April, the temperatures usually vary between 15 and 25 degrees. You can
easily fly to Málaga, unpack your bike and ride directly to the trails
close to the airport. Since it is no island (like typical winter destinations),
you can also drive all the way to south spain and benefit from the better
mobility.`}</p>
    <TrailguidePlugin content="lat=36.7223&lng=-4.4527" mdxType="TrailguidePlugin" />
    <p className="text-xs mb-16" align="center">
      <p>{`Open these trails directly in the `}<a parentName="p" {...{
          "href": "https://trailguide.net/?pos=13,36.6253,-4.5665"
        }}>{`web-app`}</a></p>
    </p>
    <h4>{`La Ermita`}</h4>
    <p>{`Right behind the city, the trails wind down the first mountain range with a
great view over the coast.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/2551"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/malaga_15.jpg" mdxType="Image" />
    <br />
    <h4>{`R3`}</h4>
    <p>{`The terrain is loose. Stones and sand make the bike moving sideways all
the time. This trail is a classic enduro trail above Benalmadena. With a
technical start, some climbs, and fast but challenging sections in
the end, it offers everything.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/2552"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/malaga_6.jpg" mdxType="Image" />
    <br />
    <p>{`Also on the same trail you find compact rock in some tricky sections.`}</p>
    <Image src="destinations/malaga_7.jpg" mdxType="Image" />
    <br />
    <h4>{`Benalmadena`}</h4>
    <p>{`In Benalmadena, which is a bit west of Málaga, you find a lift with tiny
gondolas. After taking out your frontwheel, bike and rider harldy fit inside.`}</p>
    <Image src="destinations/malaga_1.jpg" mdxType="Image" />
    <br />
    <h4>{`Lobo`}</h4>
    <p>{`The lift opens up a nice set of trails around the Monte Calamorro.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/2549"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/malaga_3.jpg" mdxType="Image" />
    <br />
    <h4>{`La Techa`}</h4>
    <p>{`On the north side of this mountain range, down to Alhaurín de la Torre,
the trails are different. Steeper, rocky and more compact. From the 900m
high Cerro de Medialuna you find
`}<a parentName="p" {...{
        "href": "https://trailguide.net/2621"
      }}>{`this`}</a>{` awesome technical trail.`}</p>
    <Image src="destinations/malaga_13.jpg" mdxType="Image" />
    <br />
    <h4>{`Alberca`}</h4>
    <p>{`One of our favourite trails. The mix between technical and flowy parts is
awesome. It winds through the thick forest with a bit of jungle feeling.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/2620"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/malaga_12.jpg" mdxType="Image" />
    <br />
    <h4>{`DH Alhaurín`}</h4>
    <p>{`This place is no secret. Pros like Gee Atherton, Sam Pilgrim & Co. have
been here a couple of times. There are a few different tracks carved into
the woods. A small road allows shuttling or an efficient climb.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/2554"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/malaga_8.jpg" mdxType="Image" />
    <br />
    <h4>{`DH Alhaurín`}</h4>
    <p>{`You will also find some jumps. Better have a look before your first try.
Find the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/2555"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/malaga_9.jpg" mdxType="Image" />
    <br />
    <h4>{`Los Almendrales`}</h4>
    <p>{`The Area of Los Almendrales is a great playground for enduro biking.
Many awesome built tracks are packed in a small area with an efficient
climb. The trails are great fun and their style is similar. They are all not
very technical, but ride them fast and they are for sure challenging.
With lots of fun corners, open turns and berms, some small jumps and
offcamber sections the trails have a really cool rythm and dance around
the sometimes tight trees. You can definetely spend at least one day here,
trying all those trails and discover even more in the forest.
Find the trails `}<a parentName="p" {...{
        "href": "https://trailguide.net/?pos=16,36.7500,-4.3957&map=cyclemap"
      }}>{`here`}</a>{`.`}</p>
    <Image src="destinations/malaga_14.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      